<template>
  <v-card class="px-5">
    <v-card-title class="header-fixed">
      <v-layout>
        <v-row>
          <div class="card-title">
            <div class="text-left pl-8">
              <h3>E Resep Dibawa Pulang</h3>
            </div>
            <div>
              <p class="title-head">No.RM</p>
            </div>
            <div class="px-0">
              <p class="data-head">{{ patientData.rmNumber }}</p>
            </div>
            <div>
              <p class="title-head">Nama</p>
            </div>
            <div class="px-0">
              <p class="data-head">
                {{ patientData.meta.name }} ({{
                  patientData.meta.gender === 'Perempuan' ? 'P' : 'L'
                }})
              </p>
            </div>
            <div>
              <p class="title-head">Tanggal Lahir</p>
            </div>
            <div class="px-0">
              <p class="data-head">
                {{ patientData.meta.birthDate }} ({{ patientData.meta.age }}
                tahun)
              </p>
            </div>
          </div>
        </v-row>
        <v-flex xs1 class="text-end">
          <v-icon @click="close()">mdi-close</v-icon>
        </v-flex>
      </v-layout>
    </v-card-title>
    <v-card-text :style="{ height: `${height}` }" class="row-scroll">
      <v-row>
        <v-col cols="12">
          <v-card elevation="2" class="pb-3 mb-4" rounded="10">
            <v-expansion-panels v-model="expand" flat>
              <v-expansion-panel>
                <v-expansion-panel-header class="d-flex justify-space-between">
                  <h4 style="font-size: 18px" ref="main">
                    {{ isHistory ? 'Riwayat Resep' : 'Input Resep Obat' }}
                  </h4>
                  <div class="action-btn-group d-flex justify-end pr-5">
                    <v-btn
                      color="#2d9cdb"
                      class="action-btn pa-0 px-2"
                      outlined
                      @click.stop="actionBtnClick('riwayat')"
                      single-line
                      >LIHAT RIWAYAT RESEP</v-btn
                    >
                    <v-btn
                      color="#2d9cdb"
                      @click.stop="actionBtnClick('input')"
                      class="action-btn pa-0 px-2 ml-3"
                      outlined
                      single-line
                      >TAMBAHKAN OBAT DAN ALKES</v-btn
                    >
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pa-5">
                  <v-row>
                    <v-col v-show="isHistory" cols="6">
                      <RecipeHistory
                        :patient-data="patientData"
                        @add-to-table="addFromHistory"
                      />
                    </v-col>
                    <v-col v-show="!isHistory" cols="4" class="pr-5">
                      <v-row class="mb-2">
                        <v-col class="text-left" cols="4">
                          <label class="form-body__title">R/</label>
                        </v-col>
                        <v-col cols="8" class="text-left">
                          <label>{{ recipeNumber }}</label>
                        </v-col>
                      </v-row>
                      <v-row class="mb-2">
                        <v-col class="text-left" cols="4">
                          <label class="form-body__title"
                            >Jenis Pembuatan</label
                          >
                        </v-col>
                        <v-col cols="8" class="text-left">
                          <v-radio-group
                            v-model="selectedRadio"
                            row
                            class="form-body__data pa-0 ma-0"
                          >
                            <v-radio
                              label="Non Racik"
                              value="nonRacik"
                            ></v-radio>
                            <v-radio label="Racik" value="racik"></v-radio>
                            <v-radio label="Alkes" value="alkes"></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                      <v-row class="mb-2" v-show="selectedRadio === 'nonRacik'">
                        <v-col cols="4" class="text-left">
                          <label class="form-body__title required"
                            >Nama Obat</label
                          >
                        </v-col>
                        <v-col cols="8">
                          <v-combobox
                            class="form-body__data mt-0"
                            :items="nonMixDrugs"
                            :filter="comboboxFilter"
                            dense
                            placeholder="Pilih Obat"
                            single-line
                            append-icon="mdi-chevron-down"
                            item-value="displayName"
                            item-text="displayName"
                            v-model="input.recipe.id_drugs"
                            :search-input.sync="searchDrug"
                            return-object
                            :loading="loading"
                            @focus="isFromHistory = false"
                            @change="isAllergic('recipe')"
                          >
                          </v-combobox>
                        </v-col>
                      </v-row>
                      <v-row class="mb-2" v-show="selectedRadio === 'racik'">
                        <v-col cols="4" pt-4 class="text-left">
                          <label class="form-body__title required"
                            >Nama Obat</label
                          >
                        </v-col>
                        <v-col cols="8"
                          ><v-combobox
                            class="form-body__data mt-0"
                            :items="drugs"
                            append-icon="mdi-chevron-down"
                            :filter="comboboxFilter"
                            placeholder="Pilih Obat"
                            dense
                            item-value="displayName"
                            item-text="displayName"
                            :search-input.sync="searchDrugMix"
                            v-model="input.mix_recipe.drugs.id_drugs"
                            return-object
                            :loading="loading"
                            @change="isAllergic('mix')"
                          ></v-combobox
                        ></v-col>
                      </v-row>
                      <v-row class="mb-4" v-show="selectedRadio === 'alkes'">
                        <v-col cols="4" pt-4 class="text-left">
                          <label class="form-body__title">Nama Alkes</label>
                        </v-col>
                        <v-col cols="8"
                          ><v-combobox
                            class="form-body__data mt-0"
                            :items="medicalTools"
                            append-icon="mdi-chevron-down"
                            placeholder="Pilih Alkes"
                            @focus="isFromHistory = false"
                            dense
                            item-value="name"
                            item-text="name"
                            :search-input.sync="searchMedicalTools"
                            v-model="input.medicalTools"
                            return-object
                            :loading="loading"
                          ></v-combobox
                        ></v-col>
                      </v-row>
                      <v-row class="mb-3" v-show="selectedRadio === 'alkes'">
                        <v-col cols="4" pt-4 class="text-left">
                          <label class="form-body__title">Jumlah</label>
                        </v-col>
                        <v-col cols="8" class="pt-1"
                          ><v-text-field
                            class="form-body__data mt-0"
                            type="number"
                            placeholder="Jumlah Alkes"
                            dense
                            v-model="input.medicalTools.quantity"
                          ></v-text-field
                        ></v-col>
                      </v-row>
                      <v-row class="mb-2" v-show="selectedRadio === 'alkes'">
                        <v-col class="text-left">
                          <v-btn
                            @click="
                              isEdit ? saveEdit('medtool') : addMedicalTools()
                            "
                            color="primary"
                            outlined
                            tile
                            class="text-none"
                            ><v-icon center>mdi-plus</v-icon>
                            <p class="btn-add__text">
                              {{ isEdit ? 'Simpan Perubahan' : 'Tambah Alkes' }}
                            </p></v-btn
                          >
                        </v-col>
                      </v-row>
                      <v-row class="mb-2" v-show="selectedRadio === 'racik'">
                        <v-col cols="4" pt-4 class="text-left">
                          <label class="form-body__title">Jenis Sediaan</label>
                        </v-col>
                        <v-col cols="8">
                          <p class="form-body__data text-left mb-0">
                            {{ input.mix_recipe.drugs.id_drugs.preparation }}
                          </p>
                        </v-col>
                      </v-row>
                      <v-row
                        v-if="
                          $store.getters.userLoggedIn.config
                            .pharmacy_mix_recipe_input === 'manual'
                        "
                        class="mb-2"
                        v-show="selectedRadio === 'racik'"
                      >
                        <v-col cols="4" pt-4 class="text-left">
                          <label class="form-body__title required"
                            >Jumlah Bahan</label
                          >
                        </v-col>
                        <v-col cols="8" class="pt-0">
                          <v-text-field
                            type="number"
                            v-model="input.mix_recipe.amount"
                            class="form-body__data text-left mb-0"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="mb-2" v-show="selectedRadio === 'nonRacik'">
                        <v-col cols="4" pt-4 class="text-left">
                          <label class="form-body__title">Jenis Sediaan</label>
                        </v-col>
                        <v-col cols="8">
                          <p class="form-body__data text-left mb-0">
                            {{ input.recipe.id_drugs.preparation || '-' }}
                          </p>
                        </v-col>
                      </v-row>
                      <v-row class="mb-3" v-show="selectedRadio === 'nonRacik'">
                        <v-col cols="4" pt-4 class="text-left">
                          <label class="form-body__title">Dosis</label>
                        </v-col>
                        <v-col cols="8" class="pt-1"
                          ><v-text-field
                            class="form-body__data mt-0"
                            readonly
                            placeholder="Dosis Obat"
                            dense
                            v-model="input.recipe.dosage"
                            :suffix="input.recipe.id_drugs.strength_unit"
                          ></v-text-field
                        ></v-col>
                      </v-row>
                      <v-row class="mb-2" v-show="selectedRadio === 'racik'">
                        <v-col cols="4" pt-4 class="text-left">
                          <label class="form-body__title">Dosis</label>
                        </v-col>
                        <v-col cols="8" class="pt-0 pt-1"
                          ><v-text-field
                            class="form-body__data mt-0 mr-2"
                            dense
                            placeholder="Dosis Obat"
                            :readonly="
                              $store.getters.userLoggedIn.config
                                .pharmacy_mix_recipe_input === 'manual'
                            "
                            v-model="input.mix_recipe.drugs.dosage"
                            :suffix="
                              input.mix_recipe.drugs.id_drugs.strength_unit
                            "
                          ></v-text-field
                        ></v-col>
                      </v-row>
                      <v-row class="mb-2" v-show="selectedRadio === 'racik'">
                        <v-col cols="12">
                          <v-chip-group
                            class="d-flex justify-space-between"
                            v-if="
                              $store.getters.userLoggedIn.config
                                .pharmacy_mix_recipe_input !== 'manual'
                            "
                            active-class="white--text blue"
                            v-model="selectedMultiple"
                          >
                            <v-chip
                              style="cursor: pointer; min-width: 47px"
                              class="d-flex justify-center"
                              v-for="(x, i) in multiple"
                              :value="x.value"
                              :key="i"
                              ><strong>{{ x.text }}</strong></v-chip
                            >
                          </v-chip-group>
                        </v-col>
                      </v-row>
                      <v-row class="mb-2" v-show="selectedRadio === 'racik'">
                        <v-col class="text-left">
                          <v-btn
                            color="primary"
                            outlined
                            tile
                            class="text-none"
                            @click="addFormRacik()"
                            ><v-icon left>mdi-plus</v-icon>
                            <p class="btn-add__text">
                              Tambahkan ke Bahan Obat Racik
                            </p></v-btn
                          >
                        </v-col>
                      </v-row>
                      <v-row v-show="selectedRadio === 'racik'">
                        <v-col style="background-color: #deeef9" pt-4>
                          <v-data-table
                            hide-default-footer
                            :headers="
                              $store.getters.userLoggedIn.config
                                .pharmacy_mix_recipe_input !== 'manual'
                                ? formRacik.headers
                                : formRacik.headersManual
                            "
                            :items="formRacik.item"
                            :items-per-page="formRacik.item.length"
                            class="add-drug"
                          >
                            <template v-slot:[`item.no`]="{ item }">
                              <tr>
                                <td class="pt-0">
                                  {{ formRacik.item.indexOf(item) + 1 }}
                                </td>
                              </tr>
                            </template>
                            <template v-slot:[`item.id_drugs`]="{ item }">
                              <tr>
                                <td class="pt-0">{{ item.id_drugs.name }}</td>
                              </tr>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                              <v-icon small @click="deleteItem(item)">
                                mdi-close
                              </v-icon>
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-row>

                      <v-row class="mb-1" v-show="selectedRadio === 'nonRacik'">
                        <v-col cols="4" pt-4 class="text-left">
                          <label class="form-body__title required"
                            >Jumlah</label
                          >
                        </v-col>
                        <v-col cols="8" class="py-0"
                          ><v-text-field
                            type="number"
                            class="form-body__data mt-0"
                            dense
                            v-model="input.recipe.quantity"
                            :suffix="input.recipe.id_drugs.package"
                          ></v-text-field
                        ></v-col>
                      </v-row>
                    </v-col>
                    <v-col v-show="!isHistory" class="px-3" cols="5">
                      <v-card
                        v-show="selectedRadio === 'alkes'"
                        width="400"
                        elevation="0"
                      >
                        <v-card-title
                          style="background-color: #eb5757"
                          class="white--text pa-2"
                          v-if="isAllergy"
                        >
                          <v-flex class="text-center" xs2>
                            <v-icon color="white" style="font-size: 40px"
                              >mdi-alert-circle</v-icon
                            >
                          </v-flex>
                          <v-flex xs9>
                            <p class="body-2 font-weight-bold text-left mb-0">
                              Pasien memiliki alergi terhadap obat <br />{{
                                drugAllergy
                              }}
                            </p>
                          </v-flex>
                        </v-card-title>
                        <v-card-text
                          style="background-color: white"
                          class="text-left pa-0"
                        >
                          <h2 class="pt-3">Riwayat Alergi :</h2>
                          <v-col class="px-4 text-left"> </v-col>
                        </v-card-text>
                      </v-card>
                      <v-row class="mb-2" v-show="selectedRadio === 'nonRacik'">
                        <v-col cols="4" class="text-left">
                          <label class="form-body__title required"
                            >Aturan Pakai</label
                          >
                        </v-col>
                        <v-col cols="8" class="pa-0">
                          <v-row>
                            <v-col cols="2">
                              <v-radio-group v-model="input.radio_usage">
                                <v-radio class="mb-5" :value="0"></v-radio>
                                <v-radio :value="1"></v-radio>
                              </v-radio-group>
                            </v-col>
                            <v-col cols="10" style="margin-left: -20px">
                              <div class="usage-rules">
                                <v-combobox
                                  :disabled="input.radio_usage !== 0"
                                  class="form-body__data pt-0 mt-0 mb-5 pt-2"
                                  append-icon="mdi-chevron-down"
                                  auto-select-first
                                  dense
                                  v-model="input.recipe.usage.dropdownInput.day"
                                  :items="[
                                    '1',
                                    '2',
                                    '3',
                                    '4',
                                    '5',
                                    '6',
                                    '7',
                                    '8',
                                    '9',
                                    '10',
                                  ]"
                                ></v-combobox>
                                <span>x</span>
                                <v-combobox
                                  :disabled="input.radio_usage !== 0"
                                  append-icon="mdi-chevron-down"
                                  class="form-body__data pt-0 mt-0 mb-5 pt-2"
                                  dense
                                  auto-select-first
                                  :items="[
                                    '1/4',
                                    '1/3',
                                    '1/2',
                                    '2/3',
                                    '3/4',
                                    '1',
                                    '2',
                                    '3',
                                    '4',
                                    '5',
                                  ]"
                                  v-model="
                                    input.recipe.usage.dropdownInput.usage
                                  "
                                ></v-combobox>
                                <v-combobox
                                  :disabled="input.radio_usage !== 0"
                                  class="form-body__data pt-0 mt-0 mb-5 pt-2"
                                  dense
                                  auto-select-first
                                  append-icon="mdi-chevron-down"
                                  clearable
                                  :items="resource.packaging.nonRacik"
                                  v-model="
                                    input.recipe.usage.dropdownInput.package
                                  "
                                >
                                </v-combobox>
                              </div>
                              <div class="usage-rules2">
                                <v-text-field
                                  :disabled="input.radio_usage !== 1"
                                  class="form-body__data pt-2 mt-0"
                                  dense
                                  v-model="input.recipe.usage.manualInput.usage"
                                ></v-text-field>
                                <v-combobox
                                  :disabled="input.radio_usage !== 1"
                                  class="form-body__data pt-0 mt-0 mb-5 pt-2"
                                  dense
                                  auto-select-first
                                  append-icon="mdi-chevron-down"
                                  clearable
                                  :items="resource.packaging.nonRacik"
                                  v-model="
                                    input.recipe.usage.manualInput.package
                                  "
                                >
                                </v-combobox>
                              </div>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-form
                        ref="racikForm"
                        v-model="racikForm"
                        lazy-validation
                      >
                        <v-row v-show="selectedRadio === 'racik'" class="pb-3">
                          <v-col cols="4" pt-3 class="text-left">
                            <label class="form-body__title required"
                              >Jumlah</label
                            >
                          </v-col>
                          <v-col cols="4"
                            ><v-text-field
                              class="form-body__data mt-0"
                              dense
                              v-model="input.mix_recipe.quantity"
                              :rules="rules.amount"
                            ></v-text-field
                          ></v-col>
                          <v-col cols="4"
                            ><v-combobox
                              class="form-body__data mt-0"
                              clearable
                              auto-select-first
                              dense
                              v-model="input.mix_recipe.packaging"
                              :items="resource.packaging.racikAmount"
                              @change="onChangeRacikAmount()"
                              append-icon="mdi-chevron-down"
                            ></v-combobox
                          ></v-col>
                        </v-row>
                        <v-row v-show="selectedRadio === 'racik'" class="pb-3">
                          <v-col cols="4" pt-2 class="text-left">
                            <label class="form-body__title required"
                              >Aturan Pakai</label
                            >
                          </v-col>
                          <v-col cols="8" pt-1>
                            <div class="usage-rules">
                              <v-combobox
                                class="form-body__data pt-0 mt-0"
                                dense
                                auto-select-first
                                append-icon="mdi-chevron-down"
                                v-model="input.mix_recipe.usage.day"
                                :rules="rules.usageRules"
                                :items="[
                                  '1',
                                  '2',
                                  '3',
                                  '4',
                                  '5',
                                  '6',
                                  '7',
                                  '8',
                                  '9',
                                  '10',
                                ]"
                              ></v-combobox>
                              <span>x</span>
                              <v-combobox
                                class="form-body__data pt-0 mt-0 mb-5"
                                dense
                                auto-select-first
                                v-model="input.mix_recipe.usage.usage"
                                append-icon="mdi-chevron-down"
                                :items="[
                                  '1/4',
                                  '1/3',
                                  '1/2',
                                  '2/3',
                                  '3/4',
                                  '1',
                                  '2',
                                  '3',
                                  '4',
                                  '5',
                                ]"
                              ></v-combobox>
                              <v-combobox
                                class="form-body__data pt-0 mt-0 mb-5"
                                dense
                                auto-select-first
                                clearable
                                append-icon="mdi-chevron-down"
                                :items="resource.packaging.racik"
                                v-model="input.mix_recipe.usage.package"
                              >
                              </v-combobox>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row class="mb-2" v-show="selectedRadio === 'racik'">
                          <v-col cols="4" class="text-left">
                            <label class="form-body__title">Rute</label>
                          </v-col>
                          <v-col cols="8" class="pt-1"
                            ><v-autocomplete
                              append-icon="mdi-chevron-down"
                              class="form-body__data mt-0"
                              placeholder="Pilih Rute"
                              dense
                              :items="resource.route"
                              v-model="input.mix_recipe.route"
                            ></v-autocomplete
                          ></v-col>
                        </v-row>
                        <v-row class="mb-2" v-show="selectedRadio === 'racik'">
                          <v-col cols="4" class="text-left">
                            <label class="form-body__title">Aturan Umum</label>
                          </v-col>
                          <v-col cols="8" class="pt-1"
                            ><v-autocomplete
                              append-icon="mdi-chevron-down"
                              placeholder="Pilih Aturan Umum"
                              class="form-body__data mt-0"
                              dense
                              :items="resource.generalRules"
                              v-model="input.mix_recipe.generalRules"
                            ></v-autocomplete
                          ></v-col>
                        </v-row>
                        <v-row v-show="selectedRadio === 'racik'">
                          <v-col cols="4" pt-4 class="text-left">
                            <label class="form-body__title"
                              >Aturan Lainnya</label
                            >
                          </v-col>
                          <v-col cols="8" pt-5>
                            <v-textarea
                              outlined
                              v-model="input.mix_recipe.description"
                            ></v-textarea>
                          </v-col>
                        </v-row>
                        <v-row v-show="selectedRadio === 'racik'">
                          <v-col>
                            <v-btn
                              outlined
                              tile
                              color="primary"
                              class="btn-add text-none"
                              @click="isEdit ? saveEdit('mix') : addRacik()"
                            >
                              <v-icon left>mdi-plus</v-icon>
                              <p class="btn-add__text">
                                {{
                                  isEdit
                                    ? 'Simpan Perubahan'
                                    : 'Tambahkan Obat Racik'
                                }}
                              </p>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-form>
                      <v-row class="mb-2" v-show="selectedRadio === 'nonRacik'">
                        <v-col cols="4" class="text-left">
                          <label class="form-body__title">Rute</label>
                        </v-col>
                        <v-col cols="8" class="pt-1"
                          ><v-autocomplete
                            append-icon="mdi-chevron-down"
                            class="form-body__data mt-0"
                            dense
                            :items="resource.route"
                            v-model="input.recipe.route"
                          ></v-autocomplete
                        ></v-col>
                      </v-row>
                      <v-row class="mb-2" v-show="selectedRadio === 'nonRacik'">
                        <v-col cols="4" class="text-left">
                          <label class="form-body__title">Aturan Umum</label>
                        </v-col>
                        <v-col cols="8" class="pt-1"
                          ><v-autocomplete
                            append-icon="mdi-chevron-down"
                            class="form-body__data mt-0"
                            dense
                            :items="resource.generalRules"
                            v-model="input.recipe.generalRules"
                          ></v-autocomplete
                        ></v-col>
                      </v-row>
                      <v-row class="mb-2" v-show="selectedRadio === 'nonRacik'">
                        <v-col cols="4" class="text-left">
                          <label class="form-body__title">Aturan Lainnya</label>
                        </v-col>
                        <v-col cols="8">
                          <v-textarea
                            no-resize
                            outlined
                            v-model="input.recipe.description"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                      <v-row v-show="selectedRadio === 'nonRacik'">
                        <v-col>
                          <v-btn
                            outlined
                            tile
                            color="primary"
                            class="btn-add text-none"
                            @click="isEdit ? saveEdit('nonmix') : addNonRacik()"
                          >
                            <v-icon left>mdi-plus</v-icon>
                            <p class="btn-add__text">
                              {{
                                isEdit
                                  ? 'Simpan Perubahan'
                                  : 'Tambahkan Obat Non Racik'
                              }}
                            </p>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col :cols="isHistory ? 6 : 3">
                      <v-card
                        v-show="selectedRadio !== 'alkes' || isHistory"
                        width="400"
                        elevation="0"
                      >
                        <v-card-title
                          style="background-color: #eb5757"
                          class="white--text pa-2"
                          v-if="isAllergy"
                        >
                          <v-flex class="text-center" xs2>
                            <v-icon color="white" style="font-size: 40px"
                              >mdi-alert-circle</v-icon
                            >
                          </v-flex>
                          <v-flex xs9>
                            <p class="body-2 font-weight-bold text-left mb-0">
                              Pasien memiliki alergi terhadap obat <br />{{
                                drugAllergy
                              }}
                            </p>
                          </v-flex>
                        </v-card-title>
                        <v-card-text
                          style="background-color: white"
                          class="text-left pa-0"
                        >
                          <h3 class="pt-3 red--text">RIWAYAT ALERGI :</h3>
                          <div class="allergies">
                            <template v-if="allergies.drug?.length">
                              <label>Alergi Obat</label>
                              <p class="pa-0 mx-0">
                                {{ allergies.drug.join(', ') }}
                              </p>
                            </template>
                            <template v-if="allergies.food?.length">
                              <label>Alergi Makanan</label>
                              <p class="pa-0 mx-0">
                                {{ allergies.food.join(', ') }}
                              </p>
                            </template>
                            <template v-if="allergies.air?.length">
                              <label>Alergi Udara</label>
                              <p class="pa-0 mx-0">
                                {{ allergies.air.join(', ') }}
                              </p>
                            </template>
                            <template v-if="allergies.other?.length">
                              <label>Alergi Lainnya</label>
                              <p class="pa-0 mx-0">
                                {{ allergies.other.join(', ') }}
                              </p>
                            </template>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>

                  <!-- New Design -->

                  <v-layout class="form-body" pl-2 pt-1>
                    <v-flex xs5> </v-flex>
                  </v-layout>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <h3 v-show="expand !== 0" class="text-left ma-2 mb-0 pl-4">
              Riwayat Alergi
            </h3>
            <v-col v-show="expand !== 0" class="px-4 text-left"> </v-col>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="px-3 grid-table">
        <div class="pa-2" style="background-color: #deeef9">
          <h3 class="text-left mb-2" style="color: #2d9cdb">
            Daftar Obat Racik dan Non Racik
          </h3>
          <v-data-table
            style="background-color: #deeef9"
            :items-per-page="drugsTable.item.length"
            hide-default-footer
            :headers="drugsTable.headers"
            :items="drugsTable.item"
            sort-by="recipeNumber"
          >
            <template v-slot:[`item.name`]="{ item }">
              <div v-if="item.drugs">
                <tr v-for="drug in item.drugs" :key="drug._id">
                  <td class="pb-3">{{ drug.name }}</td>
                </tr>
              </div>

              <div v-else>
                <tr>
                  <td class="pb-3">{{ item.name }}</td>
                </tr>
              </div>
            </template>
            <template v-slot:[`item.preparation`]="{ item }">
              <div v-if="item.drugs">
                <tr v-for="preparation in item.drugs" :key="preparation._id">
                  <td class="pb-3">{{ preparation.preparation }}</td>
                </tr>
              </div>

              <div v-else>
                <tr>
                  <td class="pb-3">{{ item.preparation }}</td>
                </tr>
              </div>
            </template>
            <template v-slot:[`item.ingredientQuantity`]="{ item }">
              <div v-if="item.drugs">
                <tr v-for="drug in item.drugs" :key="drug._id">
                  <td class="pb-3">{{ drug.quantity || '-' }}</td>
                </tr>
              </div>

              <div v-else>
                <tr>
                  <td class="pb-3">-</td>
                </tr>
              </div>
            </template>
            <template v-slot:[`item.dosage`]="{ item }">
              <div v-if="item.drugs">
                <tr v-for="dosage in item.drugs" :key="dosage._id">
                  <td class="pb-3">
                    {{ dosage.dosage_needed }}
                    {{ dosage.unit_dosage }}
                  </td>
                </tr>
              </div>

              <div v-else>
                <tr>
                  <td class="pb-3">{{ item.dosage }} {{ item.unit_dosage }}</td>
                </tr>
              </div>
            </template>
            <template v-slot:[`item.action`]="{ item, index }">
              <div class="d-flex">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="mr-4 icon-style"
                      dense
                      v-bind="attrs"
                      v-on="on"
                      @click="editDrug(item, index)"
                      v-show="item.isEditable"
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span> Ubah </span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="mr-4 icon-style"
                      dense
                      v-bind="attrs"
                      v-on="on"
                      @click="deleteDrug(item)"
                      color="red"
                      v-show="item.isDeletable"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span> Hapus </span>
                </v-tooltip>
              </div>
            </template>
          </v-data-table>
        </div>
        <div class="pa-2" style="background-color: #deeef9">
          <h3 class="text-left mb-2" style="color: #2d9cdb">Daftar Alkes</h3>
          <v-data-table
            style="background-color: #deeef9"
            :headers="medicalTable.headers"
            hide-default-footer
            :items-per-page="medicalTable.item.length"
            :items="medicalTable.item"
          >
            <template v-slot:[`item.action`]="{ item, index }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-4 icon-style"
                    dense
                    v-bind="attrs"
                    v-on="on"
                    @click="editDrug(item, index)"
                    v-show="item.isEditable"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span> Ubah </span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-4 icon-style"
                    dense
                    v-bind="attrs"
                    v-on="on"
                    @click="deleteMedicalTools(item)"
                    color="red"
                    v-show="item.isDeletable"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span> Hapus </span>
              </v-tooltip>
            </template>
          </v-data-table>
        </div>
      </v-row>
    </v-card-text>
    <v-card-actions class="pr-6">
      <v-spacer></v-spacer>
      <v-btn
        color="#137BC0"
        tile
        primary
        class="white--text"
        width="152"
        depressed
        @click="saveData()"
        :loading="saveLoading"
        >Simpan</v-btn
      >
    </v-card-actions>
    <v-snackbar
      top
      v-model="snackbar.state"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
      ><div
        class="d-flex font-weight-bold align-center body-1"
        :class="{
          'justify-center': snackbar.status === 'success',
          'justify-space-between': snackbar.status === 'error',
        }"
      >
        {{ snackbar.text }}
        <v-btn
          v-if="snackbar.status === 'error'"
          class="ml-4"
          fab
          small
          text
          @click="closeSnackbar()"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </div>
    </v-snackbar>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2';
import Constant from '@/const';
import moment from 'moment-timezone';
import AlertMixin from '@/mixin/alertMixin';
import RecipeHistory from './Erecipe/RecipeHistory.vue';
import generatePackaging from '@/helper/erecipe.js';
import { getAllergiesByIdEmr } from '@/fetchApi/EMR';

const axios = require('axios');
const _ = require('lodash');
axios.defaults.headers.post['Content-Type'] = 'application/json';

export default {
  components: {
    RecipeHistory,
  },
  mixins: [AlertMixin],
  data() {
    return {
      isEdit: false,
      editingIndex: '',
      editingRecipeNumber: '',
      expand: 0,
      snackbar: {
        state: false,
        status: '',
        color: '',
        text: '',
        timeout: 0,
      },
      multiple: [
        {
          text: '1/8',
          value: 8,
        },
        {
          text: '1/5',
          value: 5,
        },
        {
          text: '1/4',
          value: 4,
        },
        {
          text: '1/3',
          value: 3,
        },
        {
          text: '1/2',
          value: 2,
        },
        {
          text: '2/3',
          value: 0.67,
        },
        {
          text: '3/4',
          value: 0.75,
        },
        {
          text: '1',
          value: 1,
        },
      ],
      selectedMultiple: '',
      selectedRadio: 'nonRacik',
      isHistory: false,
      isFromHistory: false,
      drugsTable: {
        headers: [
          { text: 'R/', value: 'recipeNumber', sortable: false },
          { text: 'Nama Obat', value: 'name', sortable: false },
          { text: 'Jenis Sediaan', value: 'preparation', sortable: false },
          {
            text: 'Jumlah Bahan',
            value: 'ingredientQuantity',
            sortable: false,
          },
          { text: 'Dosis', value: 'dosage', sortable: false },
          { text: 'Jumlah', value: 'quantity', sortable: false },
          { text: 'Aturan Pakai', value: 'usage', sortable: false },
          { text: 'Aturan Umum', value: 'general_rule', sortable: false },
          { text: 'Aturan Lainnya', value: 'description', sortable: false },
          { text: 'Action', value: 'action', sortable: false },
        ],
        item: [],
      },
      medicalTable: {
        item: [],
        headers: [
          { text: 'R/', value: 'recipeNumber', sortable: false },
          { text: 'Nama Alkes', value: 'name', sortable: false },
          { text: 'Jumlah', value: 'quantity', sortable: false },
          { text: 'Action', value: 'action', sortable: false },
        ],
      },
      formRacik: {
        headers: [
          { text: 'No.', value: 'no', class: 'pa-0', sortable: false },
          {
            text: 'Nama Obat',
            value: 'name',
            width: '30%',
            class: 'pa-0',
            sortable: false,
          },
          {
            text: 'Jenis Sediaan',
            value: 'preparation',
            class: 'pa-0',
            sortable: false,
          },
          {
            text: 'Dosis',
            value: 'dosage_needed',
            class: 'pa-0',
            sortable: false,
          },
          { text: 'Action', value: 'actions', class: 'pa-0', sortable: false },
        ],
        headersManual: [
          { sortable: false, text: 'No.', value: 'no', class: 'pa-0' },
          {
            text: 'Nama Obat',
            value: 'name',
            width: '20%',
            class: 'pa-0',
            sortable: false,
          },
          {
            sortable: false,
            text: 'Jenis Sediaan',
            value: 'preparation',
            class: 'pa-0',
          },
          {
            sortable: false,
            text: 'Jumlah Bahan',
            value: 'quantity',
            class: 'pa-0',
          },
          {
            text: 'Dosis',
            sortable: false,
            value: 'dosage_needed',
            class: 'pa-0 pl-2',
          },
          { text: 'Action', value: 'actions', sortable: false, class: 'pa-0' },
        ],
        item: [],
      },
      allergies: {
        air: [],
        food: [],
        drug: [],
        other: [],
      },
      drugs: [],
      medicalTools: [],
      drug: '',
      input: {
        radio_usage: 0,
        recipe: {
          id_drugs: {
            preparation: '',
            strength_unit: '',
            displayName: '',
            name: '',
            strength: '',
          },
          name: '',
          preparation: '',
          dosage: '',
          route: '',
          generalRules: '',
          unit_dosage: '',
          quantity: '',
          packaging: '',
          description: '',
          usage: {
            dropdownInput: {
              usage: '',
              day: '',
              package: '',
            },
            manualInput: {
              usage: '',
              day: '',
              package: '',
            },
          },
          price: '',
        },
        mix_recipe: {
          name: '',
          drugs: {
            id_drugs: '',
            preparation: '',
            dosage: '',
            dosage_needed: '',
            unit_dosage: '',
            price: '',
          },
          quantity: '',
          packaging: '',
          description: '',
          amount: '',
          usage: {
            usage: '',
            day: '',
            package: '',
          },
          price: '',
        },
        medicalTools: {
          name: '',
          nameOnly: '',
          id: '',
          strength: '',
          strength_unit: '',
          quantity: '',
        },
      },
      loading: false,
      saveLoading: false,
      resource: {
        route: [
          'Per Oral',
          'Sublingual',
          'Per Vagina',
          'Per Rectal',
          'Intravena',
          'Intramuscular',
          'Subcutan',
          'Okular (mata)',
          'Otic (telinga)',
          'Nasal (hidung)',
          'Inhalasi',
          'Transdermal',
          'Intratekal',
          'Topikal',
          'Intrakutan',
        ],
        generalRules: [
          '15 menit sebelum makan',
          '½ jam sebelum makan',
          '1 jam sebelum makan',
          'Sebelum makan',
          '1 jam setelah makan',
          '½ jam sebelum makan',
          '2 jam  sesudah makan',
          'Sebelum suapan pertama',
          'Bersama atau tanpa makan',
          'Bersama makan',
          'Kunyah ',
          'Kunyah bersama makan',
          'Sebelum atau sesudah makan',
          'Sesudah makan',
          'Setelah suapan pertama',
          'Taruh diatas lidah',
          'Taruh dibawah lidah ',
        ],
        packaging: {
          racikAmount: ['Bungkus', 'Kapsul', 'Salep', 'Sirup'],
          racik: [
            'Bungkus',
            'Hisap',
            'Kapsul',
            'Kumur',
            'ml',
            'mg',
            'Oleskan',
            'Semprot',
            'Sendok Makan',
            'Sendok Obat',
            'Sendok Sup',
            'Sendok Teh',
            'Tablet',
            'Tetes',
            'Ampul',
            'gr',
            'Ovula',
            'Patch',
            'Rectal Tube',
            'pcs',
            'TPM',
            'Unit',
            'Flexpen',
            'Vial',
          ],
          nonRacik: [
            'Bungkus',
            'Hisap',
            'Kapsul',
            'Kumur',
            'ml',
            'mg',
            'Oleskan',
            'Semprot',
            'Sendok Makan',
            'Sendok Obat',
            'Sendok Sup',
            'Sendok Teh',
            'Tablet',
            'Tetes',
            'Ampul',
            'gr',
            'Ovula',
            'Patch',
            'Rectal Tube',
            'pcs',
            'TPM',
            'Unit',
            'Flexpen',
            'Vial',
          ],
        },
      },
      rules: {
        usageRules: [v => !!v || 'Masukkan aturan pakai'],
        amount: [v => !!v || 'Masukkan jumlah'],
      },
      racikForm: true,
      isAllergy: false,
      drugAllergy: '',
      searchDrug: '',
      searchDrugMix: '',
      searchMedicalTools: '',
    };
  },
  props: {
    patientData: {
      type: Object,
      default: null,
    },
    isInpatient: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    recipeNumber() {
      return this.drugsTable.item.length + this.medicalTable.item.length + 1;
    },
    height() {
      let height = 0;
      switch (this.$vuetify.breakpoint.name) {
        case 'md':
          height = '76vh';
          break;
        case 'lg':
          height = '82vh';
          break;
        case 'xl':
          height = '85vh';
          break;
      }
      return height;
    },
  },
  watch: {
    selectedRadio(value) {
      if (value === 'racik') {
        this.input.recipe.dosage = this.input.recipe.dosage.toFixed(2);
      }
      if (value === 'nonRacik') {
        this.input.recipe.dosage = Math.ceil(this.input.recipe.dosage);
      }
    },
    searchDrug(value) {
      if (value && !this.isFromHistory) {
        this.searchData(this);
      }
    },
    searchDrugMix(value) {
      if (value) {
        this.searchDataMix(this);
      }
    },
    searchMedicalTools(value) {
      if (value && !this.isFromHistory) {
        this.getMedicalTools(value);
      }
    },
    expand(value) {
      if (value !== 0) {
        this.isHistory = false;
      }
    },
    selectedMultiple(value) {
      if (value && this.mixDosageValue !== '') {
        if (value === 0.67) {
          this.input.mix_recipe.drugs.dosage = (this.mixDosageValue * 2) / 3;
        } else if (value === 0.75) {
          this.input.mix_recipe.drugs.dosage = this.mixDosageValue * value;
        } else {
          this.input.mix_recipe.drugs.dosage = this.mixDosageValue / value;
        }
      }
      if (value === undefined) {
        this.input.mix_recipe.drugs.dosage = this.mixDosageValue;
      }
      if (this.input.mix_recipe.drugs.dosage) {
        this.input.mix_recipe.drugs.dosage = this.input.mix_recipe.drugs.dosage.toFixed(
          2,
        );
      }
    },
  },
  methods: {
    searchData: _.debounce(v => v.getDrugs(v.searchDrug), 650),
    searchDataMix: _.debounce(v => v.getDrugs(v.searchDrugMix), 650),
    closeSnackbar() {
      this.snackbar = {
        state: false,
        status: '',
        color: '',
        text: '',
        timeout: 0,
      };
    },
    comboboxFilter(_, __, itemText) {
      return itemText;
    },
    editDrug(drug, index) {
      this.editingIndex = index;
      this.editingRecipeNumber = drug.recipeNumber;
      this.isEdit = true;
      const el = this.$refs.main;
      el.scrollIntoView({ behavior: 'smooth' });
      this.isHistory = false;
      this.isFromHistory = true;
      // non mix recipe
      if (drug.isDrug && !drug.isMixed) {
        this.selectedRadio = 'nonRacik';
        this.input.recipe.id_drugs.name = drug.name;
        this.input.recipe.id_drugs.displayName = drug.name;
        this.input.recipe.id_drugs.preparation = drug.preparation;
        this.input.recipe.id_drugs.strength_unit = drug.unit_dosage;
        this.input.recipe.id_drugs.strength = drug.dosage;
        this.searchDrug = drug.name;
        this.input.recipe.dosage = drug.dosage;
        this.input.recipe.quantity = drug.quantity;
        this.input.recipe.description = drug.description;
        this.input.recipe.route = drug.route;
        this.input.recipe.generalRules = drug.general_rule;
        if (drug.usage_type) {
          this.input.radio_usage = 0;
          this.input.recipe.usage.dropdownInput.day = drug.usage.split(' ')[0];
          this.input.recipe.usage.dropdownInput.usage = drug.usage.split(
            ' ',
          )[2];
          this.input.recipe.usage.dropdownInput.package =
            drug.usage.split(' ')[3] || '';
        }
        if (!drug.usage_type) {
          this.input.radio_usage = 1;
          this.input.recipe.usage.manualInput.usage = drug.usage.split(' ')[0];
          this.input.recipe.usage.manualInput.package = drug.usage.split(' ')[
            drug.usage.split(' ').length - 1
          ];
        }
      }
      // mix recipe
      if (drug.isDrug && drug.isMixed) {
        this.formRacik.item = [];
        this.selectedRadio = 'racik';
        this.input.mix_recipe.quantity = drug.quantity;
        this.input.mix_recipe.packaging = drug.packaging;
        this.input.mix_recipe.description = drug.description;
        this.input.mix_recipe.usage.day = drug.usage.split(' ')[0];
        this.input.mix_recipe.usage.usage = drug.usage.split(' ')[2];
        this.input.mix_recipe.usage.package =
          drug.usage.split(' ')[3] || drug.packaging;
        this.input.mix_recipe.route = drug.route;
        this.input.mix_recipe.generalRules = drug.general_rule;
        this.formRacik.item.push(...drug.drugs);
      }
      //  medical tools
      if (!drug.isDrug) {
        this.selectedRadio = 'alkes';
        this.searchMedicalTools = drug.name;
        this.input.medicalTools.name = drug.name;
        this.input.medicalTools.nameOnly = drug.nameOnly;
        this.input.medicalTools.quantity = drug.quantity;
        this.input.medicalTools.strength = drug.strength;
        this.input.medicalTools.strength_unit = drug.strength_unit;
      }
    },
    addFromHistory(drugs) {
      drugs.forEach(drug => {
        if (drug.isDrug && drug.isMix) {
          this.drugsTable.item.push({
            name: drug.name,
            isDrug: drug.isDrug,
            recipeNumber: this.recipeNumber,
            drugs: drug.drugs,
            quantity: +drug.quantity,
            packaging: drug.packaging,
            description: drug.description,
            usage: drug.usage,
            price: drug.price,
            date: drug.date,
            id_staff: drug.id_staff,
            isDeletable: true,
            isEditable: true,
            route: drug.route,
            general_rule: drug.general_rule,
            isMixed: true,
          });
        }
        if (drug.isDrug && !drug.isMix) {
          this.drugsTable.item.push({
            recipeNumber: this.recipeNumber,
            name: drug.name,
            id_drugs: drug.id,
            isDrug: drug.isDrug,
            preparation: drug.preparation,
            dosage: +drug.dosage,
            unit_dosage: drug.unit_dosage,
            quantity: drug.quantity,
            price: drug.price,
            packaging: drug.packaging,
            description: drug.description,
            usage: drug.usage,
            date: drug.date,
            isEditable: true,
            id_staff: drug.id_staff,
            isDeletable: true,
            isMixed: false,
            route: drug.route,
            general_rule: drug.general_rule,
          });
        }
        if (!drug.isDrug) {
          this.medicalTable.item.push({
            recipeNumber: this.recipeNumber,
            name: drug.name,
            nameOnly: drug.nameOnly,
            isEditable: true,
            quantity: +drug.quantity,
            date: drug.date,
            strength: drug.strength,
            strength_unit: drug.strength_unit,
            isDeletable: true,
          });
        }
      });
    },
    actionBtnClick(val) {
      switch (val) {
        case 'input':
          if (this.expand === null || this.expand === undefined) {
            this.expand = 0;
          } else {
            this.isHistory = false;
          }
          break;
        case 'riwayat':
          this.expand = 0;
          this.isHistory = true;
          break;
      }
    },
    onChangeRacikAmount() {
      switch (this.input.mix_recipe.packaging) {
        case 'Bungkus':
          this.input.mix_recipe.usage.package = 'Bungkus';
          break;
        case 'Kapsul':
          this.input.mix_recipe.usage.package = 'Kapsul';
          break;
        case 'Salep':
          this.input.mix_recipe.usage.package = 'Oleskan';
          break;
        case 'Sirup':
          this.input.mix_recipe.usage.package = 'Sendok Teh';
          break;
      }
    },
    async resolveGetAllergies() {
      try {
        const response = await getAllergiesByIdEmr(this.patientData.id_emr);
        const {
          allergy_air,
          allergy_drug,
          allergy_food,
          allergy_history,
        } = response.data.data;
        this.allergies = {
          air: allergy_air,
          food: allergy_food,
          drug: allergy_drug,
          other: allergy_history,
        };
      } catch {
        this.allergies = {
          air: [],
          food: [],
          drug: [],
          other: [],
        };
      }
    },
    saveData() {
      if (
        this.drugsTable.item.length < 1 &&
        this.medicalTable.item.length < 1
      ) {
        Swal.fire({
          title: 'Anda Belum Menambahkan Obat',
          text: 'Apakah Anda Yakin Mengirim Resep Kosong?',
          icon: 'warning',
          reverseButtons: true,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak',
        }).then(result => {
          if (result.value) {
            this.addData();
            return;
          }
        });
      } else {
        this.addData();
      }
    },
    addData() {
      this.saveLoading = true;
      axios
        .post(
          Constant.apiUrl.concat(
            `/patient/recipe/drug/recomendation/${this.patientData.id_registration}?type=&calculation_type=${this.$store.getters.userLoggedIn.config.pharmacy_mix_recipe_input}`,
          ),
          {
            id_registration: this.patientData.id_registration,
            id_emr: this.patientData.id_emr,
            patient_status: this.patientData.patientType,
            isInpatient: this.isInpatient,
            recipe: this.drugsTable.item
              .filter(drug => drug.isMixed === false)
              .map(recipe => {
                return {
                  id_drugs: recipe.id_drugs,
                  preparation: recipe.preparation,
                  dosage: recipe.dosage,
                  name: recipe.name,
                  unit_dosage: recipe.unit_dosage,
                  usage_type: recipe.usage_type,
                  quantity: recipe.quantity,
                  requested_quantity: recipe.quantity,
                  packaging: recipe.packaging,
                  description: recipe.description,
                  usage: recipe.usage,
                  general_rule: recipe.general_rule,
                  date: recipe.date,
                  recipe_number: recipe.recipeNumber,
                  staff: recipe.id_staff,
                  route: recipe.route,
                };
              }),
            mix_recipe: this.drugsTable.item
              .filter(drug => drug.isMixed === true)
              .map(racik => {
                return {
                  drugs: racik.drugs,
                  quantity: racik.quantity,
                  requested_quantity: racik.quantity,
                  packaging: racik.packaging,
                  description: racik.description,
                  usage: racik.usage,
                  general_rule: racik.general_rule,
                  price: racik.price,
                  date: racik.date,
                  staff: racik.id_staff,
                  recipe_number: racik.recipeNumber,
                  name: racik.name,
                  route: racik.route,
                };
              }),
            medical_tools: this.medicalTable.item.map(item => {
              return {
                recipe_number: item.recipeNumber,
                name: item.nameOnly,
                id_drugs: item.id,
                requested_quantity: item.quantity,
                quantity: item.quantity,
                packaging: item.packaging,
                price: item.price,
                date: item.date,
              };
            }),
            timestamps: {
              created_by: this.$store.getters.userLoggedIn.id,
            },
          },
        )
        .then(response => {
          if (response.status === 200) {
            Swal.fire('Data E-Resep berhasil disimpan!', '', 'success');
            this.close();
          }
        })
        .catch(error => {
          this.showErrorAxios(error);
        })
        .finally(() => {
          this.saveLoading = false;
        });
    },
    close() {
      this.$emit('close-form');
    },
    getAllergyFromAssessment() {
      axios
        .get(Constant.apiUrl.concat(`/patient/emr/${this.patientData.id_emr}`))
        .then(response => {
          if (response.status === 200) {
            const data = response.data.data;
            this.allergy = {
              allergy: data.checkup[0].subjective.allergy_history.filter(i =>
                i.trim(),
              ),
            };
          }
        })
        .catch(() => {
          this.allergy = [];
        });
    },
    getMedicalTools(keyword) {
      this.loading = true;
      axios
        .get(
          Constant.apiUrl.concat(
            `/master/drugs/category/group?page=1&itemCount=10&name=${
              keyword !== undefined ? keyword : ''
            }&category=Alat Kesehatan`,
          ),
        )
        .then(response => {
          const data = response.data.data;
          this.medicalTools = data.map(tool => {
            return {
              name: `${tool._id.name} ${tool._id.strength} ${tool._id.unit} ${tool._id.preparation}`,
              nameOnly: tool._id.name,
              strength: tool._id.strength,
              strength_unit: tool._id.unit,
              preparation: tool._id.preparation,
              quantity: '',
            };
          });
        })
        .catch(() => {
          this.medicalTools = [];
        })
        .finally(() => (this.loading = false));
    },
    getDrugs(keyword) {
      this.loading = true;
      axios
        .get(
          Constant.apiUrl.concat(
            `/master/drugs/category/group?name=${
              keyword !== undefined ? keyword : ''
            }&page=&itemCount=10&category=Obat`,
          ),
        )
        .then(response => {
          let data = response.data.data;
          this.nonMixDrugs = data.map(drug => {
            return {
              displayName: `${drug._id.name} ${drug._id.strength} ${drug._id.unit} ${drug._id.preparation}`,
              strength: drug._id.strength,
              strength_unit: drug._id.unit,
              preparation: drug._id.preparation,
              name: drug._id.name,
            };
          });
          this.drugs = data.map(drug => {
            return {
              displayName: `${drug._id.name} ${drug._id.strength} ${drug._id.unit} ${drug._id.preparation}`,
              strength: drug._id.strength.toFixed(2),
              strength_unit: drug._id.unit,
              preparation: drug._id.preparation,
              name: drug._id.name,
            };
          });
        })
        .catch(() => {
          this.drugs = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showAllDrugs() {
      axios
        .get(
          Constant.apiUrl.concat(
            `/patient/recipe/${this.patientData.id_registration}`,
          ),
        )
        .then(response => {
          let medical_item = response.data.data.medical_tools;
          this.medicalTable.item = medical_item
            .sort((a, b) => a.recipe_number - b.recipe_number)
            .map(item => {
              return {
                recipeNumber: item.recipe_number,
                isEditable: !item.isApproved,
                isCalculated: item.isCalculated,
                id: item._id,
                name: item.name,
                nameOnly: item.name,
                quantity: item.quantity,
                packaging: item.packaging,
                price: item.price,
                date: item.date,
              };
            });

          let data = response.data.data.mix_recipe;
          data
            .sort((a, b) => a.recipe_number - b.recipe_number)
            .map(racik => {
              const drug = {
                recipeNumber: racik.recipe_number,
                isEditable: !racik.isApproved,
                isCalculated: racik.isCalculated,
                id: racik._id,
                name: racik.name,
                drugs: racik.drugs.map(drug => {
                  return {
                    id_drugs: drug.id_drugs._id,
                    name: drug.name,
                    preparation: drug.preparation,
                    quantity: drug.requested_quantity,
                    dosage: +drug.dosage,
                    dosage_needed: drug.dosage_needed,
                    unit_dosage: drug.unit_dosage,
                    price: drug.price,
                  };
                }),
                quantity: racik.quantity,
                packaging: racik.packaging,
                description: racik.description,
                usage: racik.usage,
                price: racik.price,
                general_rule: racik.general_rule,
                route: racik.route,
                date: racik.date,
                staff: racik.staff,
                time_delivery: racik.time_delivery,
                isDeletable: false,
                isMixed: true,
                isDrug: true,
              };
              this.drugsTable.item.push(drug);
            });

          let dataNonRacik = response.data.data.recipe;
          dataNonRacik
            .sort((a, b) => a.recipe_number - b.recipe_number)
            .map(nonRacik => {
              const drug = {
                recipeNumber: nonRacik.recipe_number,
                isEditable: !nonRacik.isApproved,
                _id: nonRacik._id,
                id_drugs: nonRacik.id_drugs._id,
                name: nonRacik.name,
                preparation: nonRacik.preparation,
                dosage: +nonRacik.dosage,
                unit_dosage: nonRacik.unit_dosage,
                quantity: nonRacik.quantity,
                packaging: nonRacik.packaging,
                description: nonRacik.description,
                usage: nonRacik.usage,
                price: nonRacik.price,
                general_rule: nonRacik.general_rule,
                route: nonRacik.route,
                date: nonRacik.date,
                staff: nonRacik.staff,
                time_delivery: nonRacik.time_delivery,
                isDeletable: false,
                isMixed: false,
                isDrug: true,
                usage_type: nonRacik.usage_type,
              };
              this.drugsTable.item.push(drug);
            });
          this.drugsTable.item.sort((a, b) => a.recipeNumber - b.recipeNumber);
        })
        .catch(() => {
          this.medicalTable.item = [];
          this.drugsTable.item = [];
        });
    },
    addFormRacik() {
      if (
        !this.input.mix_recipe.drugs.id_drugs ||
        !this.input.mix_recipe.drugs.dosage ||
        (!this.input.mix_recipe.amount &&
          this.$store.getters.userLoggedIn.config.pharmacy_mix_recipe_input ===
            'manual')
      ) {
        this.snackbar = {
          status: 'error',
          state: true,
          color: '#EB4747',
          text: 'Data obat racik belum lengkap',
          timeout: -1,
        };
        return;
      }
      const formRacik = {
        name: this.input.mix_recipe.drugs.id_drugs.name,
        id_drugs: this.input.mix_recipe.drugs.id_drugs._id,
        preparation: this.input.mix_recipe.drugs.id_drugs.preparation,
        dosage: +this.input.mix_recipe.drugs.id_drugs.strength,
        unit_dosage: this.input.mix_recipe.drugs.id_drugs.strength_unit,
        packaging: this.input.mix_recipe.drugs.id_drugs.packaging,
        dosage_needed: this.input.mix_recipe.drugs.dosage,
        price: this.input.mix_recipe.drugs.id_drugs.hpp,
        quantity:
          this.$store.getters.userLoggedIn.config.pharmacy_mix_recipe_input ===
          'manual'
            ? +this.input.mix_recipe.amount
            : undefined,
      };
      this.formRacik.item.push(formRacik);
      this.selectedMultiple = '';
      this.mixDosageValue = '';
      this.input.mix_recipe.amount = '';
      this.input.mix_recipe.drugs = {
        id_drugs: '',
        dosage: '',
        dosage_needed: '',
        unit_dosage: '',
        price: '',
        preparation: '',
      };
    },
    saveEdit(type) {
      // edit non mix
      if (type === 'nonmix') {
        let isUsageFilled = false;
        if (this.input.radio_usage === 0) {
          if (
            this.input.recipe.usage.dropdownInput.day &&
            this.input.recipe.usage.dropdownInput.usage &&
            this.input.recipe.usage.dropdownInput.package
          ) {
            isUsageFilled = true;
          }
        } else {
          if (
            this.input.recipe.usage.manualInput.usage &&
            this.input.recipe.usage.manualInput.package
          ) {
            isUsageFilled = true;
          }
        }
        if (
          !this.input.recipe.id_drugs?.name ||
          !isUsageFilled ||
          !this.input.recipe.quantity ||
          this.input.recipe.quantity <= 0
        ) {
          this.snackbar = {
            status: 'error',
            state: true,
            color: '#EB4747',
            text: 'Data obat non racik belum lengkap',
            timeout: -1,
          };
          return;
        }
        var nonRacik = {};
        nonRacik = {
          name: this.input.recipe.id_drugs.name,
          recipeNumber: this.editingRecipeNumber,
          id_drugs: this.input.recipe.id_drugs._id,
          preparation: this.input.recipe.id_drugs.preparation,
          dosage: this.input.recipe.dosage,
          unit_dosage: this.input.recipe.id_drugs.strength_unit,
          quantity: this.input.recipe.quantity,
          packaging:
            this.input.radio_usage === 0
              ? this.input.recipe.usage.dropdownInput.package
              : this.input.recipe.usage.manualInput.package,
          description: this.input.recipe.description || '',
          usage:
            this.input.radio_usage === 0
              ? `${this.input.recipe.usage.dropdownInput.day} x ${this.input.recipe.usage.dropdownInput.usage} ${this.input.recipe.usage.dropdownInput.package}`
              : `${this.input.recipe.usage.manualInput.usage} ${this.input.recipe.usage.manualInput.package}`,
          price: this.input.recipe.id_drugs.hpp,
          date: moment(new Date()).format(),
          id_staff: this.$store.getters.userLoggedIn.id,
          isEditable: true,
          isDeletable: true,
          isMixed: false,
          isDrug: true,
          route: this.input.recipe.route,
          general_rule: this.input.recipe.generalRules,
        };
        this.drugsTable.item.splice(this.editingIndex, 1, nonRacik);
        this.input.recipe = {
          id_drugs: {
            preparation: '',
            strength_unit: '',
            displayName: '',
            name: '',
            strength: '',
          },
          preparation: '',
          dosage: '',
          unit_dosage: '',
          quantity: '',
          packaging: '',
          description: '',
          usage: {
            dropdownInput: {
              usage: '',
              day: '',
              package: '',
            },
            manualInput: {
              usage: '',
              day: '',
              package: '',
            },
          },
          price: '',
        };
        this.isEdit = false;
      }
      // edit mix
      if (type === 'mix') {
        if (this.formRacik.item.length === 0) {
          this.snackbar = {
            status: 'error',
            state: true,
            color: '#EB4747',
            text: 'Masukkan list obat racik terlebih dahulu',
            timeout: -1,
          };
        } else {
          if (this.$refs.racikForm.validate()) {
            const racik = {
              name: `Obat Racik ${this.editingRecipeNumber}`,
              recipeNumber: this.editingRecipeNumber,
              drugs: this.formRacik.item.map(drug => {
                if (
                  this.$store.getters.userLoggedIn.config
                    .pharmacy_mix_recipe_input === 'manual'
                ) {
                  return {
                    ...drug,
                    dosage: drug.dosage,
                    dosage_needed: (
                      (drug.dosage_needed * drug.quantity || 0) /
                      +this.input.mix_recipe.quantity
                    ).toFixed(2),
                  };
                } else {
                  return {
                    ...drug,
                    quantity: (
                      (drug.dosage_needed * +this.input?.mix_recipe?.quantity) /
                      drug.dosage
                    ).toFixed(2),
                  };
                }
              }),
              quantity: +this.input.mix_recipe.quantity,
              packaging: this.input.mix_recipe.packaging,
              description: this.input.mix_recipe.description || '',
              usage: `${this.input.mix_recipe.usage.day} x ${this.input.mix_recipe.usage.usage} ${this.input.mix_recipe.usage.package}`,
              price: 0,
              date: moment(new Date()).format(),
              id_staff: this.$store.getters.userLoggedIn.id,
              isDeletable: true,
              isEditable: true,
              isDrug: true,
              route: this.input.mix_recipe.route,
              general_rule: this.input.mix_recipe.generalRules,
              isMixed: true,
            };
            this.drugsTable.item.splice(this.editingIndex, 1, racik);
            this.input.mix_recipe = {
              name: '',
              drugs: {
                id_drugs: '',
                preparation: '',
                dosage: '',
                dosage_needed: '',
                unit_dosage: '',
                price: '',
              },
              quantity: '',
              packaging: '',
              description: '',
              usage: {
                usage: '',
                day: '',
                package: '',
              },
              price: '',
            };
            this.formRacik.item = [];
            this.$refs.racikForm.resetValidation();
          }
        }
        this.isEdit = false;
      }
      // edit medtool
      if (type === 'medtool') {
        if (
          // !this.input.medicalTools.id ||
          !this.input.medicalTools.name ||
          !this.input.medicalTools.quantity
        ) {
          this.snackbar = {
            status: 'error',
            state: true,
            color: '#EB4747',
            text: 'Data alkes belum lengkap',
            timeout: -1,
          };
          return;
        } else {
          const item = {
            recipeNumber: this.editingRecipeNumber,
            name: this.input.medicalTools.name,
            nameOnly: this.input.medicalTools.nameOnly,
            quantity: +this.input.medicalTools.quantity,
            strength: this.input.medicalTools.strength,
            strength_unit: this.input.medicalTools.strength_unit,
            date: moment(new Date()).format(),
            isDeletable: true,
            isEditable: true,
          };
          this.medicalTable.item.splice(this.editingIndex, 1, item);
          this.input.medicalTools = {
            name: '',
            quantity: '',
          };
        }
        this.isEdit = false;
      }
    },
    addNonRacik() {
      let isUsageFilled = false;
      if (this.input.radio_usage === 0) {
        if (
          this.input.recipe.usage.dropdownInput.day &&
          this.input.recipe.usage.dropdownInput.usage &&
          this.input.recipe.usage.dropdownInput.package
        ) {
          isUsageFilled = true;
        }
      } else {
        if (
          this.input.recipe.usage.manualInput.usage &&
          this.input.recipe.usage.manualInput.package
        ) {
          isUsageFilled = true;
        }
      }
      if (
        !this.input.recipe.id_drugs?.name ||
        !isUsageFilled ||
        !this.input.recipe.quantity ||
        this.input.recipe.quantity <= 0
      ) {
        this.snackbar = {
          status: 'error',
          state: true,
          color: '#EB4747',
          text: 'Data non obat racik belum lengkap',
          timeout: -1,
        };
        return;
      }
      var nonRacik = {};
      (nonRacik = {
        recipeNumber: this.recipeNumber,
        name: this.input.recipe.id_drugs.name,
        id_drugs: this.input.recipe.id_drugs._id,
        preparation: this.input.recipe.id_drugs.preparation,
        dosage: this.input.recipe.dosage,
        unit_dosage: this.input.recipe.id_drugs.strength_unit,
        quantity: this.input.recipe.quantity,
        packaging:
          this.input.radio_usage === 0
            ? this.input.recipe.usage.dropdownInput.package
            : this.input.recipe.usage.manualInput.package,
        description: this.input.recipe.description || '',
        usage:
          this.input.radio_usage === 0
            ? `${this.input.recipe.usage.dropdownInput.day} x ${this.input.recipe.usage.dropdownInput.usage} ${this.input.recipe.usage.dropdownInput.package}`
            : `${this.input.recipe.usage.manualInput.usage} ${this.input.recipe.usage.manualInput.package}`,
        price: this.input.recipe.id_drugs.hpp,
        date: moment(new Date()).format(),
        id_staff: this.$store.getters.userLoggedIn.id,
        usage_type: this.input.radio_usage === 0,
        isDeletable: true,
        isDrug: true,
        isMixed: false,
        isEditable: true,
        route: this.input.recipe.route,
        general_rule: this.input.recipe.generalRules,
      }),
        this.drugsTable.item.push(nonRacik);
      this.input.recipe = {
        id_drugs: {
          preparation: '',
          strength_unit: '',
          displayName: '',
          name: '',
          strength: '',
        },
        preparation: '',
        dosage: '',
        unit_dosage: '',
        quantity: '',
        packaging: '',
        description: '',
        usage: {
          dropdownInput: {
            usage: '',
            day: '',
            package: '',
          },
          manualInput: {
            usage: '',
            day: '',
            package: '',
          },
        },
        price: '',
      };
      this.recipeNumber += 1;
      this.snackbar = {
        status: 'success',
        state: true,
        color: 'success',
        text: 'Berhasil Menambahkan Obat Non Racik',
        timeout: 2000,
      };
    },
    addRacik() {
      if (this.formRacik.item.length === 0) {
        this.snackbar = {
          status: 'error',
          state: true,
          color: '#EB4747',
          text: 'Masukkan list obat racik terlebih dahulu',
          timeout: -1,
        };
      } else {
        if (this.$refs.racikForm.validate()) {
          const racik = {
            name: `Obat Racik ${this.recipeNumber}`,
            recipeNumber: this.recipeNumber,
            drugs: this.formRacik.item.map(drug => {
              if (
                this.$store.getters.userLoggedIn.config
                  .pharmacy_mix_recipe_input === 'manual'
              ) {
                return {
                  ...drug,
                  dosage: drug.dosage,
                  dosage_needed: (
                    (drug.dosage_needed * drug.quantity || 0) /
                    +this.input.mix_recipe.quantity
                  ).toFixed(2),
                };
              } else {
                return {
                  ...drug,
                  quantity: (
                    (drug.dosage_needed * +this.input?.mix_recipe?.quantity) /
                    drug.dosage
                  ).toFixed(2),
                };
              }
            }),
            isEditable: true,
            quantity: +this.input.mix_recipe.quantity,
            packaging: this.input.mix_recipe.packaging,
            description: this.input.mix_recipe.description || '',
            usage: `${this.input.mix_recipe.usage.day} x ${this.input.mix_recipe.usage.usage} ${this.input.mix_recipe.usage.package}`,
            price: 0,
            date: moment(new Date()).format(),
            id_staff: this.$store.getters.userLoggedIn.id,
            isDeletable: true,
            route: this.input.mix_recipe.route,
            general_rule: this.input.mix_recipe.generalRules,
            isDrug: true,
            isMixed: true,
          };
          this.drugsTable.item.push(racik);
          this.input.mix_recipe = {
            name: '',
            drugs: {
              id_drugs: '',
              preparation: '',
              dosage: '',
              dosage_needed: '',
              unit_dosage: '',
              price: '',
            },
            quantity: '',
            packaging: '',
            description: '',
            usage: {
              usage: '',
              day: '',
              package: '',
            },
            price: '',
          };
          this.formRacik.item = [];
          this.recipeNumber += 1;
          this.$refs.racikForm.resetValidation();
          this.snackbar = {
            status: 'success',
            state: true,
            color: 'success',
            text: 'Berhasil Menambahkan Obat Racik',
            timeout: 2000,
          };
        }
      }
    },
    addMedicalTools() {
      if (!this.input.medicalTools.name || !this.input.medicalTools.quantity) {
        this.snackbar = {
          status: 'error',
          state: true,
          color: '#EB4747',
          text: 'Data alkes belum lengkap',
          timeout: -1,
        };
        return;
      } else {
        const item = {
          recipeNumber: this.recipeNumber,
          name: this.input.medicalTools.name,
          nameOnly: this.input.medicalTools.nameOnly,
          quantity: +this.input.medicalTools.quantity,
          strength: this.input.medicalTools.strength,
          isEditable: true,
          strength_unit: this.input.medicalTools.strength_unit,
          date: moment(new Date()).format(),
          isDeletable: true,
        };
        this.medicalTable.item.push(item);
        this.input.medicalTools = {
          name: '',
          quantity: '',
        };
        this.recipeNumber += 1;
        this.snackbar = {
          status: 'success',
          state: true,
          color: 'success',
          text: 'Berhasil Menambahkan Alkes',
          timeout: 2000,
        };
      }
    },
    deleteItem(item) {
      var index = this.formRacik.item.indexOf(item);
      this.formRacik.item.splice(index, 1);
    },
    deleteDrug(item) {
      var index = this.drugsTable.item.indexOf(item);
      this.medicalTable.item = this.medicalTable.item.map(item => {
        if (item.recipeNumber < this.drugsTable.item[index].recipeNumber) {
          return {
            ...item,
          };
        }
        if (item.recipeNumber > this.drugsTable.item[index].recipeNumber) {
          return {
            ...item,
            recipeNumber: item.recipeNumber - 1,
          };
        }
      });
      this.drugsTable.item = this.drugsTable.item.map((drug, idx) => {
        if (index > idx) {
          return {
            ...drug,
          };
        }
        if (index < idx) {
          return {
            ...drug,
            recipeNumber: drug.recipeNumber - 1,
          };
        }
      });
      this.drugsTable.item.splice(index, 1);
    },
    deleteMedicalTools(item) {
      var index = this.medicalTable.item.indexOf(item);
      this.drugsTable.item = this.drugsTable.item.map(drug => {
        if (drug.recipeNumber < this.medicalTable.item[index].recipeNumber) {
          return {
            ...drug,
          };
        }
        if (drug.recipeNumber > this.medicalTable.item[index].recipeNumber) {
          return {
            ...drug,
            recipeNumber: drug.recipeNumber - 1,
          };
        }
      });
      this.medicalTable.item = this.medicalTable.item.map((item, idx) => {
        if (index > idx) {
          return {
            ...item,
          };
        }
        if (index < idx) {
          return {
            ...item,
            recipeNumber: item.recipeNumber - 1,
          };
        }
      });
      this.medicalTable.item.splice(index, 1);
    },
    isAllergic(type) {
      if (this.selectedRadio === 'nonRacik') {
        this.input.recipe.usage.dropdownInput.package = generatePackaging(
          this.input.recipe.id_drugs.preparation,
        ); // sync package with drug preparation
        this.input.recipe.dosage = this.input.recipe.id_drugs.strength;
      }
      if (this.selectedRadio === 'racik') {
        this.input.recipe.dosage = this.input.recipe.id_drugs.strength; // sync dosage with selected drug
      }
      if (type === 'recipe') {
        this.isAllergy = this.allergy.allergy.includes(
          this.input.recipe.id_drugs.name.toLowerCase(),
        );
        this.drugAllergy = this.isAllergy
          ? this.input.recipe.id_drugs.displayName
          : '';
      } else {
        this.input.mix_recipe.drugs.dosage = this.input.mix_recipe.drugs.id_drugs.strength; // sync dosage with selected drug
        this.mixDosageValue = this.input.mix_recipe.drugs.id_drugs.strength; // sync dosage with selected drug
        this.isAllergy = this.allergy.allergy.includes(
          this.input.mix_recipe.drugs.id_drugs.name.toLowerCase(),
        );
        this.drugAllergy = this.isAllergy
          ? this.input.mix_recipe.drugs.id_drugs.displayName
          : '';
      }
    },
  },

  mounted() {
    this.$nextTick(function() {
      this.getAllergyFromAssessment();
      this.getDrugs();
      this.showAllDrugs();
      this.getMedicalTools();
      this.resolveGetAllergies();
    });
  },
};
</script>

<style lang="scss" scoped>
.allergies {
  margin-top: 3vh;
  text-align: start;
  overflow-y: auto;

  label {
    color: #9e9e9e;
    margin-bottom: 0.5vw;
  }
  p {
    color: #404040;
    margin-bottom: 0.3vw;
  }
}
.header-fixed {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
  background: white;
}
.card-title {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1.7fr 0.3fr 0.6fr 0.3fr 1.8fr 0.6fr 1.2fr;
  column-gap: 20px;
}
.title-head {
  font-size: 14px;
}

.usage-rules {
  display: grid;
  grid-template-columns: 1fr 0.5fr 1fr 2fr;
  column-gap: 10px;
  span {
    padding-top: 9px;
  }
}

::v-deep .v-input--radio-group.v-input--radio-group--row .v-radio {
  margin-right: 8px;
}

.usage-rules2 {
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 10px;
}

.grid-table {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  column-gap: 10px;
}

.action-btn {
  min-height: 0px !important;
  height: 30px !important;
  font-size: 12px;
}

.allergy {
  background-color: #fff2f2 !important;
  font-weight: 400;
  font-size: 13px;
  justify-content: center;
  border: 0.5px solid #eb4747 !important;
  color: #eb4747;
}

.data-head {
  font-size: 14px;
  font-weight: 700;
  text-align: left;
}
.head-table {
  border-top: 1px solid #f4f5f5;
  border-bottom: 1px solid #f4f5f5;
  text-align: left;
  &__title {
    font-size: 14px;
    font-weight: 700;
    color: black;
    text-align: left;
  }
}

.body-table {
  border-bottom: 1px solid #f4f5f5;
  &__data {
    font-size: 14px;
    font-weight: 400;
    display: inline-block;
  }
}

.form-body {
  &__title {
    font-size: 14px;
    font-weight: 700;
    color: black;
    text-align: left;
  }
  &__data {
    height: 20px;
  }
}
.add-drug {
  max-height: 200px;
  background-color: #deeef9;
  overflow-y: scroll;
  background-color: #deeef9;
  &::-webkit-scrollbar {
    width: 3px;
    background-color: rgb(163, 163, 163);
  }
  &::-webkit-scrollbar-thumb {
    width: 3px;
    background-color: black;
  }
}
.row-scroll {
  overflow-y: scroll;
  padding: 0 40px !important;
  // height: 440px;
  position: relative;
  &::-webkit-scrollbar {
    width: 3px;
    background-color: rgb(163, 163, 163);
  }
  &::-webkit-scrollbar-thumb {
    width: 3px;
    background-color: black;
  }
  .btn-add {
    display: flex;
    justify-content: flex-start;
    height: 35px !important;
    &__text {
      min-width: 100%;
      margin-bottom: 0;
      font-size: 0.8rem;
      white-space: normal;
      width: auto;
    }
  }
}

::v-deep .v-list-item .v-list-item__content {
  line-height: 1.1;
  flex: 1 0 100%;
  text-align: start;
}

.required:after {
  content: ' *';
  color: red;
}
</style>
