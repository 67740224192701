import JsPDF from 'jspdf';
import QRCode from 'qrcode';
import moment from 'moment-timezone';
import printMixin from './printMixin';
import odontogramLegend from '@/assets/image/odontogram_legend.png';
import physicalExamination from '@/helper/physicalExamination.js';
const { formatAddress } = require('@/helper');

export default {
  methods: {
    generateSigner(data) {
      return data.doctor_by?.[0]?.role?.[0]?.role?.length
        ? 'Dokter'
        : 'Perawat';
    },
    generateOutpatientSummary(
      patientData,
      emrData,
      refImage = null,
      isAssessmentGeneral,
    ) {
      const clientData = this.$store.getters.userLoggedIn;
      const allAllergies = emrData.subjective.allergy_drug.concat(
        emrData.subjective.allergy_food,
        emrData.subjective.allergy_air,
        emrData.subjective.allergy_history,
      );

      const doc = new JsPDF({
        orientation: 'portrait',
        unit: 'in',
        format: 'a4',
      });

      const marginX = 1;
      const perLine = doc.getTextDimensions(' ').h;
      const addLine = line => perLine * line + 1;
      let lastDidDrawPageData = null;

      const alignCenter = (text, addingLine, y, x = 0) => {
        doc.text(
          text,
          doc.internal.pageSize.getWidth() / 2 -
            doc.getTextDimensions(text).w / 2 +
            x,
          y || addLine(addingLine),
          { align: 'left', charSpace: 0 },
        );
      };
      const pageHeight =
        doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
      doc.setProperties({
        title: `Ringkasan Keluar Rawat Jalan`,
      });

      doc.setFontSize(14);
      doc.setFont('Helvetica', 'bold');
      alignCenter('RINGKASAN KELUAR RAWAT JALAN', undefined, addLine(4));
      doc.autoTable({
        body: [
          ['No RM', ':', patientData.meta.rmNumber],
          ['Nama', ':', patientData.meta.name],
          ['Alamat', ':', formatAddress(patientData.meta.address)],
        ],
        startY: addLine(6),
        styles: {
          halign: 'left',
          textColor: 0,
        },
        columnStyles: {
          0: { cellWidth: 1, cellPadding: 0.05 },
          1: { cellWidth: 0.17, cellPadding: 0.05 },
          2: { cellWidth: 2.5, cellPadding: 0.05 },
        },
        didDrawCell: e => {
          e.doc.internal.write(0, 'Tw');
        },
        margin: {
          left: marginX,
          right: marginX,
        },
        theme: 'plain',
      });
      doc.autoTable({
        body: [
          ['Tanggal Lahir', ':', patientData.meta.birthDate],
          [
            'Jenis Kelamin',
            ':',
            patientData.meta.gender === true ? 'Laki-laki' : 'Perempuan',
          ],
          ['Ruang/Poliklinik', ':', patientData.meta.poly],
          ['Tipe Penjamin', ':', patientData.meta.guarantorType.toUpperCase()],
        ],
        startY: addLine(6),
        styles: {
          halign: 'left',
          textColor: 0,
        },
        columnStyles: {
          0: { cellWidth: 1.2, cellPadding: 0.05 },
          1: { cellWidth: 0.17, cellPadding: 0.05 },
          2: { cellWidth: 2.5, cellPadding: 0.05 },
        },
        didDrawCell: e => {
          e.doc.internal.write(0, 'Tw');
        },
        margin: {
          left: marginX + 3.8,
          right: marginX,
        },
        theme: 'plain',
      });
      const isAdult = () => {
        const birthDate = moment(patientData.meta.birthDate, 'DD MMMM YYYY');
        const diff = moment().diff(birthDate, 'month');
        return diff > 228;
      };
      const nutritionCalculation = data => {
        let score = 0,
          result;
        if (isAdult()) {
          score =
            (data?.isFoodIntakePoorDueToDecreasedAppetite?.score || 0) +
            (data?.isHaveSeriousIllness?.score || 0) +
            (data?.isUnplannedWeightLoss?.score || 0);

          if (isNaN(score)) {
            result = null;
          } else if (score <= 1) {
            result = 'Tidak Berisiko Malnutrisi';
          } else if (score === 2) {
            result = 'Risiko Malnutrisi';
          } else {
            result = 'Malnutrisi';
          }
        } else {
          score =
            (data?.isThereCausesMalnourished?.score || 0) +
            (data?.isThereOfTheseCondition?.score || 0) +
            (data?.isThin?.score || 0) +
            (data?.isWeightLossLastMonth?.score || 0);

          if (isNaN(score)) {
            result = null;
          } else if (score === 0) {
            result = 'Berisiko Rendah';
          } else if (score >= 1 && score <= 3) {
            result = 'Berisiko Sedang';
          } else {
            result = 'Berisiko Tinggi';
          }
        }
        return result;
      };
      const kidAgeScore = () => {
        let result,
          age = patientData.meta.age;
        if (age < 3) {
          result = 4;
        }
        if (age >= 3 && age <= 7) {
          result = 3;
        }
        if (age > 7 && age <= 13) {
          result = 2;
        }
        if (age > 13) {
          result = 1;
        }
        return result;
      };
      const fallRiskAssesmentResult = data => {
        const ageScore = kidAgeScore();
        let score, result;
        if (isAdult()) {
          score =
            (data?.fallHistory?.score || 0) +
            (data?.secondaryDiagnosis?.score || 0) +
            (data?.walkTool?.score || 0) +
            (data?.useHeparinLockTherapy?.score || 0) +
            (data?.howToWalk?.score || 0) +
            (data?.mentalStatus?.score || 0);
          if (isNaN(score)) {
            result = null;
          }
          if (score <= 24) {
            result = score + ' - Tidak Berisiko Jatuh';
          }
          if (score > 24 && score <= 50) {
            result = score + ' - Risiko Jatuh Rendah';
          }
          if (score > 50) {
            result = score + ' - Risiko Jatuh Tinggi';
          }
        } else {
          score =
            (patientData.meta.gender === 'Laki-laki' ||
            patientData.meta.gender === true
              ? 2
              : 1) +
            (ageScore || 0) +
            (data?.diagnosis?.score || 0) +
            (data?.cognitiveImpairment?.score || 0) +
            (data?.surgery?.score || 0) +
            (data?.medicinalUse?.score || 0);
          if (isNaN(score)) {
            result = null;
          }
          if (score < 7) {
            result = score + ' - Tidak Berisiko Jatuh';
          }
          if (score >= 7 && score <= 11) {
            result = score + ' - Risiko Jatuh Rendah';
          }
          if (score > 11) {
            result = score + ' - Risiko Jatuh Tinggi';
          }
        }
        return result;
      };

      // Content General Poly and Poly KIA
      if (
        patientData.meta.poly.toLowerCase() === 'poli umum' ||
        patientData.meta.poly.toLowerCase() === 'poli kia' ||
        patientData.meta.poly.toLowerCase() === 'poli fisioterapi'
      ) {
        const filteredPhysical = emrData?.assesment?.physical
          ? Object.entries(emrData?.assesment?.physical)
              .filter(
                ([, value]) =>
                  value.toLowerCase() !== 'normal' &&
                  value.toLowerCase() !== '',
              )
              .map(([key, value]) => {
                return {
                  label: physicalExamination.find(
                    physical => physical.respond === key,
                  )?.label,
                  value,
                };
              })
          : [];
        const itemsExFirstPhysical = filteredPhysical.slice(1).map(item => {
          return ['', '', `${item?.label}: ${item.value}`];
        });

        let body = [
          [
            'Tanggal',
            ':',
            moment(emrData.timestamps.doctor_created_at).format(
              'DD-MM-YYYY [;] HH.mm [WIB]',
            ),
          ],
          [
            'Keluhan Utama',
            ':',
            emrData.subjective.anamnesis.join(', ') || '-',
          ],
          [
            'Riwayat Alergi',
            ':',
            allAllergies.length ? allAllergies.join(', ') : '-',
          ],
          [
            'Riwayat Penyakit',
            ':',
            emrData.subjective.disease_history.join(', ') || '-',
          ],
          [
            'Riwayat Pengobatan',
            ':',
            emrData.subjective.treatment_history || '-',
          ],
          [
            'Status Psikologis',
            ':',
            emrData.subjective.psychological_status.join(', ') || '-',
          ],
          ['Sosial Ekonomi', ':', emrData.subjective.social_economy || '-'],
          ['Spiritual', ':', emrData.subjective.spiritual || '-'],
          [
            'TTV',
            ':',
            `TD ${emrData.objective.sistol}/${
              emrData.objective.diastol
            } mm/Hg, HR ${emrData.objective.heart_rate} x/menit, RR ${
              emrData.objective.respiration_rate
            } x/menit, Suhu ${emrData.objective.temperature} C, SpO2 ${emrData
              .objective.saturation || '-'} %, Tinggi ${
              emrData.objective.height
            } cm, Berat ${emrData.objective.weight} kg`,
          ],
          [
            'Pemeriksaan Fisik',
            ':',
            filteredPhysical.length === 0
              ? '-'
              : `${filteredPhysical[0]?.label}: ${filteredPhysical[0]?.value}`,
          ],
          ...itemsExFirstPhysical,
          [
            'Skrining Gizi',
            ':',
            emrData.nutrition_screening
              ? nutritionCalculation(emrData.nutrition_screening)
              : '-',
          ],
          [
            'Assesmen Risiko Jatuh',
            ':',
            emrData?.assesment?.fallRisk
              ? fallRiskAssesmentResult(emrData.assesment.fallRisk)
              : '-',
          ],
          [
            'Hasil Penunjang Lainnya',
            ':',
            `Hasil EKG: ${emrData.other?.ekg ||
              '-'}, Hasil Laboratorium: ${emrData.other?.laboratory ||
              '-'}, Hasil Radiologi: ${emrData.other?.radiology ||
              '-'}, Catatan Lain: ${emrData.other?.other || '-'}`,
          ],
          [
            'Diagnosa Utama',
            ':',
            emrData?.assesment?.main_diagnose?.diagnose?.code
              ? `${
                  emrData.assesment.main_diagnose.diagnose.code
                } - ${emrData.assesment?.main_diagnose?.diagnose?.description?.replace(
                  /^Z\d+\.\d+\s/,
                  '',
                )}`
              : '-',
          ],
          [
            'Diagnosa Sekunder',
            ':',
            emrData.assesment.other_diagnose
              .map(item => {
                return `${item.code} - ${item.diagnose?.description?.replace(
                  /^Z\d+\.\d+\s/,
                  '',
                )}`;
              })
              .join(', ') || '-',
          ],
          ['Tindakan', ':', emrData.planning.treatment.join(', ') || '-'],
          ['Rencana Terapi', ':', emrData.planning.therapy.join(', ') || '-'],
          ['Status Pulang', ':', emrData.return_status_name],
        ];

        if (isAssessmentGeneral) {
          const otherObjectiveData = [
            'Data Objektif Lain',
            ':',
            `${emrData.objective?.other || '-'}`,
          ];

          const diagnoses = emrData.assesment?.diagnoses?.length
            ? emrData.assesment?.diagnoses?.map(item => {
                return [
                  'Diagnosa (ICD-10)',
                  ':',
                  `${item?.text} - ${
                    item?.code ? `${item?.code} ${item.description}` : ''
                  }}`,
                ];
              })
            : [['Diagnosa (ICD-10)', ':', '-']];

          const interventions = emrData.planning?.interventions?.length
            ? emrData.planning?.interventions?.map((item, index) => {
                return [
                  `Intervensi ${index + 1}`,
                  ':',
                  `${item?.text} - ${
                    item?.icd9?.[0]?.code
                      ? `${item?.icd9?.[0]?.code} ${item?.icd9?.[0]?.description}`
                      : ''
                  } `,
                ];
              })
            : [[`Intervensi`, ':', '-']];

          const therapies = emrData.planning?.general_theraphy
            ? emrData.planning?.general_theraphy.map(item => {
                return `${item.text} ${
                  item.icd9?.code
                    ? `- ${item.icd9?.code} ${item.icd9?.description}`
                    : ''
                }`;
              })
            : '-';

          const otherSupportingResults = body.splice(
            12 + itemsExFirstPhysical.length,
            1,
          )[0];
          body.splice(10 + itemsExFirstPhysical.length, 5);
          body.splice(9, 0, otherObjectiveData); // insert other objective data to the body
          body.splice(
            11 + itemsExFirstPhysical.length,
            0,
            otherSupportingResults,
          ); // other supporting results reposition
          body.splice(12 + itemsExFirstPhysical.length, 0, ...diagnoses); // insert diagnoses data to the body
          body.splice(
            12 + itemsExFirstPhysical.length + diagnoses.length,
            0,
            ...interventions,
          ); // insert intervensions data to the body
          body.splice(body.length - 2, 1, [
            'Rencana Terapi',
            ':',
            therapies.join(', ') || '-',
          ]); // reassign therapies for general assessment
        }

        doc.autoTable({
          body,
          startY: addLine(12.5),
          styles: {
            halign: 'left',
            textColor: 0,
            fontSize: 11,
          },
          columnStyles: {
            0: { cellWidth: 2, cellPadding: 0.05 },
            1: { cellWidth: 0.1, cellPadding: 0.01 },
            2: { cellPadding: 0.025 },
          },
          didDrawPage: d => {
            lastDidDrawPageData = d;
          },
          margin: {
            left: marginX,
            right: marginX,
            top: addLine(3),
            bottom: pageHeight - 10,
          },
          theme: 'plain',
        });
      }

      // Content Dental
      if (patientData.meta.poly.toLowerCase() === 'poli gigi') {
        doc.autoTable({
          body: [
            [
              'Tanggal',
              ':',
              moment(emrData.timestamps.doctor_created_at).format(
                'DD-MM-YYYY [;] HH.mm [WIB]',
              ),
            ],
            [
              'Keluhan Utama',
              ':',
              emrData.subjective.anamnesis.join(', ') || '-',
            ],
            [
              'Riwayat Alergi',
              ':',
              allAllergies.length ? allAllergies.join(', ') : '-',
            ],
            [
              'Riwayat Penyakit',
              ':',
              emrData.subjective.disease_history.join(', ') || '-',
            ],
            [
              'Riwayat Pengobatan',
              ':',
              emrData.subjective.treatment_history || '-',
            ],
            [
              'Status Psikologis',
              ':',
              emrData.subjective.psychological_status.join(', ') || '-',
            ],
            ['Sosial Ekonomi', ':', emrData.subjective.social_economy || '-'],
            ['Spiritual', ':', emrData.subjective.spiritual || '-'],
            [
              'TTV',
              ':',
              `TD ${emrData.objective.sistol}/${emrData.objective.diastol} mm/Hg, HR ${emrData.objective.heart_rate} x/menit, RR ${emrData.objective.respiration_rate} x/menit, Suhu ${emrData.objective.temperature} C, SpO2 ${emrData.objective.saturation} %, Tinggi ${emrData.objective.height} cm, Berat ${emrData.objective.weight} kg`,
            ],
            ['Pemeriksaan Fisik', ':'],
          ],
          styles: {
            halign: 'left',
            textColor: 0,
            fontSize: 11,
          },
          columnStyles: {
            0: { cellWidth: 2, cellPadding: 0.05 },
            1: { cellWidth: 0.1, cellPadding: 0.01 },
            2: { cellPadding: 0.025 },
          },
          didDrawCell: e => {
            e.doc.internal.write(0, 'Tw');
          },
          startY: addLine(12.5),
          margin: {
            left: marginX,
            right: marginX,
            top: addLine(3),
            bottom: pageHeight - 10,
          },
          theme: 'plain',
        });

        const scale = 50 / 100;

        doc.addImage(
          odontogramLegend,
          'PNG',
          1,
          doc.autoTable.previous.finalY + 0.1,
          2.23 * scale,
          3.25 * scale,
        );

        doc.addImage(
          refImage.getStage().toDataURL({
            pixelRatio: 4,
          }),
          'PNG',
          3,
          doc.autoTable.previous.finalY + 0.3,
          8.58 * scale,
          2.92 * scale,
        );

        const odontogramForm = emrData.odontogram_form.map(item => {
          return [
            item.tooth_number,
            `${emrData.assesment.main_diagnose.diagnose.code} - ${emrData.assesment.main_diagnose.diagnose.description}`,
            item.planning.treatment,
          ];
        });

        doc.autoTable({
          head: [
            odontogramForm.length !== 0 && ['No Gigi', 'Diagnosa', 'Tindakan'],
          ],
          body: [...odontogramForm],
          startY: doc.autoTable.previous.finalY + 2.5,
          styles: {
            halign: 'left',
            textColor: 0,
            fontSize: 10,
            lineWidth: 0.005,
            lineColor: [0, 0, 0],
          },
          headStyles: {
            fontStyle: 'normal',
            fillColor: [158, 158, 158, 0.1],
          },
          columnStyles: {
            0: { cellWidth: 1 },
            1: { cellWidth: 3 },
          },
          didDrawCell: e => {
            e.doc.internal.write(0, 'Tw');
          },
          margin: {
            left: marginX,
            right: marginX,
            top: addLine(3),
            bottom: pageHeight - 10,
          },
        });

        doc.autoTable({
          body: [
            [
              'Diagnosa Utama',
              ':',
              `${
                emrData.assesment.main_diagnose.diagnose.code
              } - ${emrData?.assesment?.main_diagnose?.diagnose?.description?.replace(
                /^Z\d+\.\d+\s/,
                '',
              )}`,
            ],
            [
              'Diagnosa Sekunder',
              ':',
              emrData.assesment.other_diagnose
                .map(item => {
                  return `${item.code} - ${item.diagnose?.description?.replace(
                    /^Z\d+\.\d+\s/,
                    '',
                  )}`;
                })
                .join(', ') || '-',
            ],
            ['Tindakan ', ':', emrData.planning.treatment.join(', ') || '-'],
            ['Rencana Terapi', ':', emrData.planning.therapy.join(', ') || '-'],
            [
              'Skrining Gizi',
              ':',
              emrData.nutrition_screening
                ? nutritionCalculation(emrData.nutrition_screening)
                : '-',
            ],
            [
              'Assesmen Risiko Jatuh',
              ':',
              emrData?.assesment?.fallRisk
                ? fallRiskAssesmentResult(emrData.assesment.fallRisk)
                : '-',
            ],
            [
              'Hasil Penunjang Lainnya',
              ':',
              `Hasil EKG: ${emrData.others.ekg
                .map(item => item.result)
                .join(', ') ||
                '-'}, Hasil Laboratorium: ${emrData.others.laboratory
                .map(item => item.result)
                .join(', ') ||
                '-'}, Hasil Radiologi: ${emrData.others.radiology
                .map(item => item.result)
                .join(', ') || '-'}, Catatan Lain: ${emrData.others.other
                .map(item => item.result)
                .join(', ') || '-'}`,
            ],
            ['Status Pulang', ':', emrData.return_status_name],
          ],
          startY: doc.autoTable.previous.finalY + 0.1,
          styles: {
            halign: 'left',
            textColor: 0,
            fontSize: 11,
          },
          columnStyles: {
            0: { cellWidth: 2, cellPadding: 0.05 },
            1: { cellWidth: 0.1, cellPadding: 0.01 },
            2: { cellPadding: 0.025 },
          },
          didDrawPage: d => {
            lastDidDrawPageData = d;
          },
          margin: {
            left: marginX,
            right: marginX,
            top: addLine(3),
            bottom: pageHeight - 10,
          },
          theme: 'plain',
        });
      }

      let finalY = doc.lastAutoTable.finalY + 0.5;
      if (lastDidDrawPageData.cursor.y <= 8.4) {
        this.generateQRCode(
          {
            letterName: 'Ringkasan Keluar Rawat Jalan',
            doctor: emrData.timestamps?.doctor_by?.[0]?.detail?.[0]?.name,
            sip: emrData.timestamps?.doctor_by?.[0]?.detail?.[0]?.sip || '',
            doc: doc,
            x: marginX - 0.3,
            y: finalY,
          },
          QRCode,
          moment,
        );
        doc.autoTable({
          body: [
            [
              {
                content: `${
                  this.$store.getters.userLoggedIn.clinic.clinicRegion
                }, ${moment(emrData.timestamps.created_at)
                  .locale('id')
                  .format('DD MMMM YYYY')}`,
                styles: { halign: 'center' },
              },
            ],
            [
              {
                content: isAssessmentGeneral
                  ? this.generateSigner(emrData.timestamps)
                  : 'Dokter Penanggungjawab',
                styles: { halign: 'center' },
              },
            ],
            [{ content: '', styles: { halign: 'center', minCellHeight: 0.7 } }],
            [
              {
                content: `${emrData.timestamps.doctor_by?.[0]?.detail?.[0]
                  ?.name ||
                  emrData.timestamps.nurse_by?.[0]?.detail?.[0]?.name}`,
                styles: { halign: 'center' },
              },
            ],
          ],
          startY: finalY + 0.25,
          styles: {
            align: 'center',
            fontSize: 11,
          },
          columnStyles: {
            0: { cellWidth: 3, cellPadding: 0.01 },
            1: { cellWidth: 3, cellPadding: 0.01 },
            2: { cellWidth: 3 },
            3: { cellWidth: 4 },
          },
          margin: {
            left: marginX + 4,
            right: marginX,
            top: addLine(3),
            bottom: pageHeight - 10,
          },
          theme: 'plain',
        });
      }
      if (lastDidDrawPageData.cursor.y >= 8.4) {
        doc.addPage();
        finalY = 1.6;
      }
      if (lastDidDrawPageData.cursor.y >= 8.4) {
        this.generateQRCode(
          {
            letterName: 'Ringkasan Keluar Rawat Jalan',
            doctor: emrData.timestamps.doctor_by?.[0]?.detail?.[0]?.name,
            sip: emrData.timestamps.doctor_by?.[0]?.detail?.[0]?.sip || '',
            doc: doc,
            x: marginX - 0.3,
            y: finalY,
          },
          QRCode,
          moment,
        );
        doc.autoTable({
          body: [
            [
              {
                content: `${
                  this.$store.getters.userLoggedIn.clinic.clinicRegion
                }, ${moment(emrData.timestamps.created_at)
                  .locale('id')
                  .format('DD MMMM YYYY')}`,
                styles: { halign: 'center' },
              },
            ],
            [
              {
                content: isAssessmentGeneral
                  ? this.generateSigner(emrData.timestamps)
                  : 'Dokter Penanggungjawab',
                styles: { halign: 'center' },
              },
            ],
            [{ content: '', styles: { halign: 'center', minCellHeight: 0.7 } }],
            [
              {
                content: `${emrData.timestamps.doctor_by?.[0]?.detail?.[0]
                  ?.name ||
                  emrData.timestamps.nurse_by?.[0]?.detail?.[0]?.name}`,
                styles: { halign: 'center' },
              },
            ],
          ],
          startY: finalY + 0.25,
          styles: {
            align: 'center',
            fontSize: 11,
          },
          columnStyles: {
            0: { cellWidth: 3, cellPadding: 0.01 },
            1: { cellWidth: 3, cellPadding: 0.01 },
            2: { cellWidth: 3 },
            3: { cellWidth: 4 },
          },
          margin: {
            left: marginX + 4,
            right: marginX,
            top: addLine(3),
            bottom: pageHeight - 10,
          },
          theme: 'plain',
        });
      }

      const pageCount = doc.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        // Letter Header
        printMixin.methods.letterHead(doc, clientData.clinic);
        // Letter Footer
        printMixin.methods.letterFooter(doc, moment, clientData.name);
      }

      return doc;
    },
  },
};
